import React from 'react';
import {
  Flex,
  Image,
  Skeleton,
  Text,
} from '@chakra-ui/react'

function Footer() {
  return (
    <Flex w='100%' justifyContent='center' flexWrap='wrap' bgColor='#15185b' py='40px'>
      <Image
        src="https://promocaokenkoenergy.com/wp-content/uploads/2022/01/LOGO-ENERGY-branco.png"
        alt="Kenko Energy Magnéticos"
        fallback={<Skeleton />}
        width="145px"
        height="42px"
      />
      <Text width='100%' textAlign='center' color='#ffffff' fontSize={12} mt={3}>Copyright 2023. Todos os direitos reservados.</Text>
    </Flex>
  );
}

export default Footer;
