import React from 'react';
import {
  Container,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import ReactLogo from '../../assets/bug.svg';
import MassageIcon from '../../assets/massage.svg';
import ColumnIcon from '../../assets/column.svg';
import './styles.scss';

function Cta() {
  return (
    <Flex
      w="100%"
      backgroundImage='https://promocaokenkoenergy.com/wp-content/uploads/2021/06/bg-cta3.jpg'
      backgroundSize='cover'
      backgroundRepeat='no-repeat'
      backgroundPosition='center'
    >
      <div className='container'>
        <Flex w='40%' justifyContent='flex-start' wrap='wrap' padding='60px 0'>
          <Container maxW='7xl'>
            <Heading size='xl' color='blue.500'>Tenha melhores noites de sono com o melhor colchão pra sua coluna</Heading>
            <Text fontSize={20} color='gray.600' mt={8}>Fale com os nossos atendentes para encontrar uma cama perfeita para você.</Text>
          </Container>
        </Flex>
      </div>
    </Flex>
  );
}

export default Cta;
