import React from 'react';
import { Box, Heading } from '@chakra-ui/react'
import { ProductCard } from './ProductCard'
import { ProductGrid } from './ProductGrid'
import { differentials, differentials2 } from './_data'
import { Differentials2 } from './Differentials2';

function WhyDifferentials() {
  return (
    <>
      <Heading size='2xl' w='100%' textAlign='center' fontWeight="medium" color='blue.500' mt={12}>
        Porque a Kenko Energy é diferente?
      </Heading>
      <Box
        maxW="7xl"
        mx="auto"
        px={{ base: '4', md: '8', lg: '12' }}
        py={{ base: '6', md: '8', lg: '12' }}
      >
        <ProductGrid>
          {differentials.map((item) => (
            <ProductCard key={item.id} differential={item} />
          ))}
        </ProductGrid>
        <ProductGrid mt={10}>
          {differentials2.map((item) => (
            <Differentials2 key={item.id} differential={item} />
          ))}
        </ProductGrid>
      </Box>
    </>
  )
}

export default WhyDifferentials;