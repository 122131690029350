export const images = [
  {
    id: '01',
    src: 'https://images.unsplash.com/photo-1602024242516-fbc9d4fda4b6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80',
    alt: 'Awesome watch',
  },
  {
    id: '02',
    src: 'https://images.unsplash.com/photo-1451290337906-ac938fc89bce?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1777&q=80',
    alt: 'Awesome watch',
  },
  {
    id: '03',
    src: 'https://images.unsplash.com/photo-1568010434570-74e9ba7126bc?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1500&q=80',
    alt: 'Awesome watch',
  },
  {
    id: '04',
    src: 'https://images.unsplash.com/photo-1569411032431-07598b0012c2?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80',
    alt: 'Awesome watch',
  },
  {
    id: '05',
    src: 'https://images.unsplash.com/photo-1565440962783-f87efdea99fd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=936&q=80',
    alt: 'Awesome watch',
  },
  {
    id: '06',
    src: 'https://images.unsplash.com/photo-1548169874-53e85f753f1e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1420&q=80',
    alt: 'Awesome watch',
  },
]

export const differentials = [
  {
    id: '1',
    name: 'Certificação',
    currency: 'USD',
    imageUrl: 'https://promocaokenkoenergy.com/wp-content/uploads/2021/06/inmetro.jpg',
    description: 'Produtos de alta qualidade certificados pelo INMETRO e ANVISA.',
  },
  {
    id: '2',
    name: '20 anos de mercado',
    imageUrl: 'https://promocaokenkoenergy.com/wp-content/uploads/2021/06/Kenko-loja.jpg',
    description: 'Profissionais altamente preparados para lhe atender e resolver qualquer dúvida.',
  },
  {
    id: '3',
    name: 'Cama Box',
    imageUrl: 'https://promocaokenkoenergy.com/wp-content/uploads/2021/11/colchao-ortopedico-2-e1636038596891.jpeg',
    description: 'mais resistente do mercado',
  }
]

export const differentials2 = [
  {
    id: '1',
    name: 'Pagamento',
    imageUrl: 'https://promocaokenkoenergy.com/wp-content/uploads/2021/06/parcelamento.jpg',
    description: 'facilitado nos cartões de crédito',
  },
  {
    id: '2',
    name: 'Frete grátis',
    imageUrl: 'https://promocaokenkoenergy.com/wp-content/uploads/2021/06/frete.jpg',
    description: 'para qualquer lugar do Brasil',
  },
  {
    id: '3',
    name: 'Garantia',
    imageUrl: 'https://promocaokenkoenergy.com/wp-content/uploads/2021/06/garantia.jpg',
    description: 'Até 7 dias para devolução grátis, caso fique insatisfeito.',
  },
  {
    id: '4',
    name: 'Tecnologia de ponta',
    imageUrl: 'https://promocaokenkoenergy.com/wp-content/uploads/2021/06/tecnologia.jpg',
    description: 'Pastilhas de Infravermelho Red USA que auxilia no processo de RENOVAÇÃO CELULAR',
  }
]

export type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<
  infer ElementType
>
  ? ElementType
  : never

export type Differential = ElementType<typeof differentials>
export type Differential2 = ElementType<typeof differentials2>