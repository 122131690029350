import React from 'react';
import {
  Container,
  Flex,
  Heading,
  List,
  ListItem,
  Text,
} from '@chakra-ui/react'
import { SlEnergy } from 'react-icons/sl';
import { BiHappyHeartEyes } from 'react-icons/bi'
import { BsFillHeartPulseFill } from 'react-icons/bs'
import { FaRunning } from 'react-icons/fa'
import './styles.scss';

function BenefitsList() {
  return (
    <Flex
      w="100%"
      bgColor="#e0edf2"
    >
      <Flex w='100%' bg='rgb(43, 108, 176, 0.8)' justifyContent='center' alignItems='center' wrap='wrap' padding='80px 0'>
        <Container maxW='7xl'>
          <Heading size='2xl' color='blue.100' textAlign='center'>Quais os benefícios para sua vida?</Heading>
          <List w='100%' mt={12} display='flex' justifyContent='space-between' gap="0 30px">
            <ListItem className='item'>
              <SlEnergy fontSize={60} fill='#e0edf2'/>
              <Heading w='100%' color='blue.100' textAlign='center' size='md' mt={6}>Mais Energia</Heading>
              <Text w='100%' color='gray.100' textAlign='center' size='md' mt={2}>Maior qualidade de sono repõe melhor a sua disposição, você consegue raciocinar mais, se exercitar melhor e seu dia rende mais.</Text>
            </ListItem>
            <ListItem className='item'>
              <BiHappyHeartEyes fontSize={60} fill='#e0edf2'/>
              <Heading w='100%' color='blue.100' textAlign='center' size='md' mt={6}>Mais Feliz</Heading>
              <Text w='100%' color='gray.100' textAlign='center' size='md' mt={2}>Estudos revelam que pessoas que dormem entre 6 e 8 horas por dia têm mais chances de evitar a depressão.</Text>
            </ListItem>
            <ListItem className='item'>
              <BsFillHeartPulseFill fontSize={60} fill='#e0edf2'/>
              <Heading w='100%' color='blue.100' textAlign='center' size='md' mt={6}>Mais Saudável</Heading>
              <Text w='100%' color='gray.100' textAlign='center' size='md' mt={2}>Quando se dorme na quantidade adequada e se chega ao sono profundo, os níveis de pressão arterial melhoram.</Text>
            </ListItem>
            <ListItem className='item'>
              <FaRunning fontSize={60} fill='#e0edf2'/>
              <Heading w='100%' color='blue.100' textAlign='center' size='md' mt={6}>Mais Leve</Heading>
              <Text w='100%' color='gray.100' textAlign='center' size='md' mt={2}>Dormir mais libera leptina, o hormônio da saciedade. Quem dorme pouco, grelina, o hormônio dá fome e reduz o gasto de energia.</Text>
            </ListItem>
          </List>
        </Container>
      </Flex>
    </Flex>
  );
}

export default BenefitsList;
