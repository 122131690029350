import React from 'react';
import {
  Flex,
  Box,
  Text,
  Button,
  Container,
  Heading,
  Stack,
  Input
} from '@chakra-ui/react'
import './styles.scss';

function Banner() {
  return (
    <Box
      bgSize='cover'
      bgImage='https://promocaokenkoenergy.com/wp-content/uploads/2021/06/Ensaio_Kenko_0060-scaled.jpg'
      w='100%'
      h='calc(100vh - 87px)'
    >
      <Flex w='100%' height='100%' justifyContent='space-between' bgColor='rgba(000, 000, 000, 0.3)'>

        {/* <Flex className='container' justifyContent='space-between' alignItems='flex-start' pt={40}> */}
        <Flex className='container' justifyContent='space-between' alignItems='flex-start' pt={{ base: '8', '2xl': 10 }}>
          <Box as="section" bgColor='rgba(000, 000, 000, 0.4)' p={8}>
            <Stack spacing={{ base: '8', md: '10' }}>
              <Stack spacing={{ base: '4', md: '5' }} align="left">
                <Heading className='title' color='#e0edf2' size={{ base: 'sm', md: '2xl' }}>Pronto para noites incríveis?</Heading>
                <Text maxW="2xl" textAlign="center" fontSize="xl" color='#e0edf2'>
                  Adquira aqui seu Colchão Ortopédico direto da fábrica
                </Text>
              </Stack>
            </Stack>
          </Box>

          <Box as="form" className='form' w='35%' bg='rgb(41, 87, 164, 0.8)' borderRadius={12}>
            <Heading className='form-title' color='#e0edf2' size={{ base: 'sm', md: 'md' }}>
              Quer acordar com mais energia e sem dores nas costas?
            </Heading>
            <Text maxW="2xl" textAlign="left" color='#ffffff' fontSize="md" mt={3} mb={10}>
              Fale com os nossos atendentes e tenha a melhor cama da sua vida
            </Text>

            <Flex justifyContent={'space-between'} flexWrap={'wrap'}>
              <Box w='100%'>
                <Text  color='#ffffff'mb='0'>Nome</Text>
                <Input placeholder='Como gostaria de ser chamado(a)?' bg='#ffffff' size='lg' fontSize='md' mt={2} />
              </Box>
              <Box w='100%' mt={18}>
                <Text  color='#ffffff'mb='0'>Cidade/Estado</Text>
                <Input placeholder='Onde você mora?' bg='#ffffff' size='lg' fontSize='md' mt={2} />
              </Box>
              <Box w='100%' mt={18}>
                <Text  color='#ffffff'mb='0'>Celular</Text>
                <Input placeholder='Ex: (xx) 99999-9999 (WhatsApp)' bg='#ffffff' size='lg' fontSize='md' mt={2} />
              </Box>
              <Button w='100%' bg='#1E9F2F' color='#ffffff' fontSize={18} mt={6} py={6}>Quero minha cama nova!</Button>
            </Flex>
          </Box>
        </Flex>

      </Flex>
    </Box>
  );
}

export default Banner;
