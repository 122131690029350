import {
  AspectRatio,
  Box,
  Heading,
  Image,
  Skeleton,
  Stack,
  StackProps,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { Differential } from './_data'

interface Props {
  differential: Differential
  rootProps?: StackProps
}

export const ProductCard = (props: Props) => {
  const { differential, rootProps } = props
  const { name, description, imageUrl } = differential
  return (
    <Stack spacing={{ base: '4', md: '5' }} {...rootProps}>
      <Box position="relative">
        <AspectRatio ratio={4 / 3}>
          <Image
            src={imageUrl}
            alt={name}
            draggable="false"
            fallback={<Skeleton />}
            borderRadius={{ base: 'md', md: 'xl' }}
          />
        </AspectRatio>
      </Box>
      <Stack>
        <Stack spacing="1" textAlign='center'>
          <Heading size='md' fontWeight="medium" color={useColorModeValue('gray.700', 'gray.400')} mb={3}>
            {name}
          </Heading>
          <Text>
            {description}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  )
}