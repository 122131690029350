import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

function BenefitOne() {
  return (
    <Box maxW="7xl" mx="auto" px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '12' }}>
      <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
        <Box
          width={{ lg: 'md' }}
          transform={{ base: 'translateY(-50%)', lg: 'none' }}
          bg={{ base: useColorModeValue('blue.50', 'gray.700'), lg: 'transparent' }}
          mx={{ base: '6', md: '8', lg: '0' }}
          px={{ base: '6', md: '8', lg: '0' }}
          py={{ base: '6', md: '8', lg: '5' }}
        >
          <Stack spacing={{ base: '8', lg: '10' }}>
            <Stack spacing={{ base: '2', lg: '4' }}>
              <Heading size="xl" color={useColorModeValue('blue.500', 'blue.300')}>
                Dormir em um colchão ruim pode causar danos na coluna cervical
              </Heading>
              <Text>
                A má postura durante o sono é uma das principais causas de dores crônicas na coluna. O desalinhamento da coluna exerce uma contração muscular muito forte na região cervical que desencadeia inflamação, sensação de pontadas e enrijecimento.
              </Text>
            </Stack>
          </Stack>
        </Box>
        <Flex flex="1" overflow="hidden">
          <Image
            src="https://promocaokenkoenergy.com/wp-content/uploads/2021/06/dor-coluna.jpg"
            alt="Dormir em um colchão ruim pode causar danos na coluna cervical"
            fallback={<Skeleton />}
            maxH="450px"
            minW="300px"
            objectFit="cover"
            flex="1"
          />
        </Flex>
      </Stack>
    </Box>
  );
}

export default BenefitOne;
