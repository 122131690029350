import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

function Differentials() {
  return (
    <Box maxW="7xl" mx="auto" px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '12' }}>
      <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
        <Box
          width={{ lg: 'sm' }}
          transform={{ base: 'translateY(-50%)', lg: 'none' }}
          bg={{ base: useColorModeValue('blue.50', 'gray.700'), lg: 'transparent' }}
          mx={{ base: '6', md: '8', lg: '0' }}
          px={{ base: '6', md: '8', lg: '0' }}
          py={{ base: '6', md: '8', lg: '5' }}
        >
          <Stack spacing={{ base: '8', lg: '10' }}>
            <Stack spacing={{ base: '2', lg: '4' }}>
              <Heading size="xl" color={useColorModeValue('blue.500', 'blue.300')}>
                Nosso Diferencial
              </Heading>
              <Heading size="xl" fontWeight="normal">
                Por que nos escolher?
              </Heading>
              <Text>
                A Kenko Energy trabalha com o conceito que se obtém uma vida saudável também através de boas noites de sono.
              </Text>
              <Text>
                Por isso investimos em tecnologia de ponta, buscando a cada dia inovações que garantem um <strong>sono tranquilo e saudável.</strong>
              </Text>
              <Text>
                <strong>Nossos produtos tem certificação INMETRO</strong>, são de fabricação própria e passaram por rigorosos processos de análise de qualidade, resistência, controle sanitário na missão de entregar o melhor produto do mercado.
              </Text>
            </Stack>
          </Stack>
        </Box>
        <Flex flex="1" overflow="hidden">
          <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ay80HNLuBX8" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
        </Flex>
      </Stack>
    </Box>
  );
}

export default Differentials;
