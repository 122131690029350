import React from 'react';
import {
  Box,
  Flex,
  Heading,
  Image,
  Skeleton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

function Differentials() {
  return (
    <Box maxW="7xl" mx="auto" px={{ base: '0', lg: '12' }} py={{ base: '0', lg: '12' }}>
      <Stack direction={{ base: 'column-reverse', lg: 'row' }} spacing={{ base: '0', lg: '20' }}>
      <Flex flex="1" overflow="hidden">
          <Image
            src="https://promocaokenkoenergy.com/wp-content/uploads/2021/11/dor-nas-costas.jpeg"
            alt="Noites mal dormidas causam desconforto e irritabilidade"
            fallback={<Skeleton />}
            maxH="450px"
            minW="300px"
            objectFit="cover"
            flex="1"
          />
        </Flex>
        <Box
          width={{ lg: 'md' }}
          transform={{ base: 'translateY(-50%)', lg: 'none' }}
          bg={{ base: useColorModeValue('blue.50', 'gray.700'), lg: 'transparent' }}
          mx={{ base: '6', md: '8', lg: '0' }}
          px={{ base: '6', md: '8', lg: '0' }}
          py={{ base: '6', md: '8', lg: '5' }}
        >
          <Stack spacing={{ base: '8', lg: '10' }}>
            <Stack spacing={{ base: '2', lg: '4' }}>
              <Heading size="xl" color={useColorModeValue('blue.500', 'blue.300')}>
                Noites mal dormidas causam desconforto e irritabilidade
              </Heading>
              <Text>
                Sonolência diurna, dificuldades em manter o foco atencional, perda ou diminuição da força física, preguiça, certa impulsividade e irritabilidade são sintomas de péssimas noites de sono. O corpo fica em um estado de constante alerta, o que eleva a pressão sanguínea durante a noite prejudicando sua saúde.
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}

export default Differentials;
