import React from 'react';
import {
  Container,
  Flex,
  Heading,
  Image,
  List,
  ListItem,
  Skeleton,
  Text,
} from '@chakra-ui/react'
import ReactLogo from '../../assets/bug.svg';
import MassageIcon from '../../assets/massage.svg';
import ColumnIcon from '../../assets/column.svg';
import './styles.scss';

function Video() {
  return (
    <Flex
      w="100%"
      backgroundImage='https://promocaokenkoenergy.com/wp-content/uploads/2021/07/serta-mattress-family-1200x777-1.jpg'
      backgroundSize='cover'
      backgroundRepeat='no-repeat'
      backgroundPosition='center'
    >
      <Flex w='100%' bg='rgb(43, 108, 176, 0.8)' justifyContent='center' alignItems='center' wrap='wrap' padding='120px 0'>
        <Container maxW='7xl'>
          <Heading size='2xl' color='blue.100' textAlign='center'>Um verdadeiro tratamento de saúde enquanto seu corpo descansa</Heading>
          <List w='100%' mt={12} display='flex' justifyContent='space-between'>
            <ListItem>
              <Image
                display='block'
                className='icon'
                m='0 auto'
                src={ReactLogo}
                alt="Tecido antiácaro e antifungos"
                fallback={<Skeleton />}
                h="100px"
                w="100px"
                objectFit="cover"
                flex="1"
              />
              <Heading w='100%' color='blue.100' textAlign='center' size='md' mt={6}>Tecido antiácaro e antifungos</Heading>
              <Text w='100%' color='gray.100' textAlign='center' size='md' mt={2}>Com pastilhas Antibac que mata 99,4% das bactérias.</Text>
            </ListItem>
            <ListItem>
              <Image
                display='block'
                className='icon'
                m='0 auto'
                src={MassageIcon}
                alt="Massagem através de ondas vibratórias"
                fallback={<Skeleton />}
                h="100px"
                w="100px"
                objectFit="contain"
                flex="1"
              />
              <Heading w='100%' color='blue.100' textAlign='center' size='md' mt={6}>Massagem através de ondas vibratórias</Heading>
              <Text w='100%' color='gray.100' textAlign='center' size='md' mt={2}>Com tecnologia aprovada pelo Inmetro, regulável por controle e aplicativo.</Text>
            </ListItem>
            <ListItem>
              <Image
                display='block'
                className='icon'
                m='0 auto'
                src={ColumnIcon}
                alt="Alívio das dores na coluna"
                fallback={<Skeleton />}
                h="100px"
                w="100px"
                objectFit="contain"
                flex="1"
              />
              <Heading w='100%' color='blue.100' textAlign='center' size='md' mt={6}>Alívio das dores na coluna</Heading>
              <Text w='100%' color='gray.100' textAlign='center' size='md' mt={2}>Com correção ortopédica.</Text>
            </ListItem>
          </List>
        </Container>
      </Flex>
    </Flex>
  );
}

export default Video;
