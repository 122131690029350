import React from 'react';
import Header from './components/Header/Header';
import Banner from './components/Banner/Banner';
import Differentials from './components/Differentials/Differentials';
import Video from './components/Video/Video';
import BenefitOne from './components/Benefits/BenefitOne/BenefitOne';
import BenefitTwo from './components/Benefits/BenefitTwo/BenefitTwo';
import BenefitsList from './components/BenefitsList/BenefitsList';
import WhyDifferentials from './components/WhyDifferentials/WhyDifferentials';
import Cta from './components/Cta/Cta';
import Footer from './components/Footer/Footer';
import './App.scss';

function App() {
  return (
    <div className="App">
      <Header />
      <Banner />
      <Differentials />
      <Video />
      <BenefitOne />
      <BenefitTwo />
      <BenefitsList />
      <WhyDifferentials />
      <Cta />
      <Footer />
    </div>
  );
}

export default App;
