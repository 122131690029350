import React from 'react';
import { Center, Image, Link   } from '@chakra-ui/react'
import './styles.scss';

function Header() {
  return (
    <header>
      <Center>
        <Link href='https://chakra-ui.com' isExternal>
          <Image
            width={200}
            objectFit='cover'
            src='https://promocaokenkoenergy.com/wp-content/uploads/2022/01/LOGO-ENERGY-NOVO.png'
            alt='Kenko Energy'
          />
        </Link>
      </Center>
    </header> 
  );
}

export default Header;
